/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      phone_number_is_hidden: true,
      init: function() { // JavaScript to be fired on all pages

        // Give IE some SVG love
        // Only required if we use SVG symbol spritemaps
        // svg4everybody();

        // Figure out if the user can hover
        // See: https://medium.com/@david.gilbertson/the-only-way-to-detect-touch-with-javascript-7791a3346685
        // window.addEventListener('mouseover', function onFirstHover() {
        //   document.body.classList.remove('no-hover');
        //   window.removeEventListener('mouseover', onFirstHover, false);
        // }, false);

        var isTouch = 'ontouchstart' in document.documentElement;

        if (isTouch) {
          document.body.classList.add('touch');
        }

        // Foundation JavaScript
        $(document).foundation();

        // Lazy load all images flagged with the "lazy" class
        new LazyLoad({
          elements_selector: '.lazy',
          threshold: 100,
        });

        // Mobile menu burger to X observer
        var topMenu = document.querySelector('#top-menu')
        var observer = new MutationObserver(function(mutations) {
          mutations.forEach( function(mutation) {
            if (mutation.attributeName !== 'class') return;
            if (mutation.target.classList.contains('mui-enter-active')) {
              $('body').addClass('js--show-mobile-nav');
            } else if (mutation.target.classList.contains('mui-leave-active')) {
              $('body').removeClass('js--show-mobile-nav');
            }
          });
        });
        observer.observe(topMenu, { attributes: true });

        // Reset burger if we resize past medium breakpoint
        $(window).resize(function() {
          if ($(document).width() >= 680) {
            $('body').removeClass('js--show-mobile-nav');
          }
        });

        // Show/hide phone on scroll
        Sage.common.showHidePhone();

        $('#body').on('scrollme.zf.trigger', function() {
          Sage.common.showHidePhone();
        });
      
        /**
         * Email subscription form in the footer
         */
        $('#js--email-subscription-form').on('submit', function(e){
          e.preventDefault();

          $('#js--email-subscription-error').hide();
          $('#js--email-subscription-success').hide();

          $.ajax({
            url: ajax_object.ajax_url,
            type: 'post',
            dataType: 'text',
            data: { action: 'mailchimp_subscribe', email: $(this).find('[name=email]').val() }
          })
          .done(function(result) {
            if (result === 1) {
              $('#js--email-subscription-form [name=email]').val('');
              $('#js--email-subscription-success').show();
            } else {
              $('#js--email-subscription-error').show();
            }
          });

          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
      showHidePhone: function() {
        if (!$('body').hasClass('contact')) {
          var triggerY = $('footer').offset().top - 100;
          if (window.pageYOffset > 100 && window.pageYOffset + window.innerHeight < triggerY) {
            if (Sage.common.phone_number_is_hidden) {
              Sage.common.phone_number_is_hidden = false;
              Foundation.Motion.animateIn($('.js--phone-number'), 'wunder-zoom-in');
            }
          } else if (!Sage.common.phone_number_is_hidden) {
            Sage.common.phone_number_is_hidden = true;
            Foundation.Motion.animateOut($('.js--phone-number'), 'wunder-zoom-out');
          }
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
